
import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
import { notification } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import validate from '../services/validator'
import router from './../router'
import IClient from './../interface/profile'
import profileService from './../services/profile'

export default defineComponent({
  components: {
    VueGoogleAutocomplete
  },
  setup () {
    const title = ref<string>(process.env.VUE_APP_PROJECT_NAME)
    const addressExist = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const rules = validate.signupValidation
    const formRef = ref()
    const formState: UnwrapRef<IClient> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      client_name: '',
      client_logo: '',
      certificate_access: true,
      created_by: '',
      profile_type: '',
      is_active: true,
      status: 'unpaid',
      send_login: true,
      address: '',
      address_details: {}
    })
    const getAddressData = async (data) => {
      const coordinates = []
      coordinates.push(data.latitude)
      coordinates.push(data.longitude)
      formState.address_details.country = data.country
      formState.address_details.location = { coordinates: coordinates, type: 'Point' }
      formState.address_details.address1 = data.route
      formState.address_details.address2 = data.locality
      formState.address_details.postal_code = data.postal_code
      console.log('formState', formState)
      addressExist.value = true
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }
    const createClient = async (values: IClient) => {
      console.log('signup', values)
      try {
        values.send_login = true
        loading.value = true
        if (addressExist.value) {
          console.log('values.address_details', values.address_details)
          const address = await profileService.addAddress(values.address_details)
          values.address = address.data._id
        } else {
          await notify('Error', 'Address is required to create client', 'error')
          loading.value = false
          return
        }
        const responce = await profileService.createNewClient(values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          resetForm()
          await notify('Success', 'Your account has been setuped and login credentials send to your email id.', 'success')
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const signup = async () => {
      console.log('signup', formState)
      createClient(formState)
    }
    const handleFinishFailed = (errors: ValidateErrorEntity<IClient>) => {
      console.log('errors', errors)
    }
    const login = async () => {
      router.push('/login')
    }
    return {
      formState,
      signup,
      rules,
      loading,
      handleFinishFailed,
      login,
      getAddressData,
      formRef,
      title
    }
  }
})
